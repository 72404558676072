<template>
    <table class="w-full">
        <tr class="bg-gray-50">
            <td class="py-1 px-2 text-sm font-semibold" :colspan="1">
                <p>
                    KONTO
                </p>
            </td>
            <td v-if="periodize" class="w-24 py-1 px-2 text-sm font-semibold">
                <p class="text-right">
                    STARTDATUM
                </p>
            </td>
            <td v-if="periodize" class="w-24 py-1 px-2 text-sm font-semibold">
                <p class="text-right">
                    STOPPDATUM
                </p>
            </td>
            <td class="py-1 px-2 text-sm font-semibold" v-if="writeComments">
                <p>
                    KOMMENTAR
                </p>
            </td>
            <td class="w-24 py-1 px-2 text-sm font-semibold">
                <p class="text-right">
                    DEBET
                </p>
            </td>
            <td class="w-24 py-1 px-2 text-sm font-semibold">
                <p class="text-right">
                    KREDIT
                </p>
            </td>
            <td />
        </tr>
        <VoucherRow
            v-for="(row, index) in voucherRows"
            :key="index"
            :account-plan-items="accountPlanItems"
            :company-type="companyType"
            :financial-years="financialYears"
            @approve="createNewItemComponent(index, $event)"
            @removeEntry="removeVoucherRow(index)"
            @updateValues="updateValues(index, $event)"
            :voucher-row="row"
            :debit-summary="debitSummary"
            :credit-summary="creditSummary"
            :warning-border="warningElements.includes(row.id)"
            :write-comments="writeComments"
            :periodize="periodize"
            @createVoucher="createVoucher(index)"
            @showSelectPeriodModal="showSelectPeriodModal(index, $event)"
        />
        <SummaryRow :debit-summary="debitSummary" :credit-summary="creditSummary" :periodize="periodize" />
        <DifferenceRow :debit-summary="debitSummary" :credit-summary="creditSummary" :periodize="periodize" />
    </table>
</template>
<script>
export default {
    components: {
        VoucherRow: () => import(/* webpackChunkName: "VoucherRow" */ "./VoucherRow.vue"),
        SummaryRow: () => import(/* webpackChunkName: "VoucherSummaryRow" */ "./SummaryRow.vue"),
        DifferenceRow: () => import(/* webpackChunkName: "VoucherDifferenceRow" */ "./DifferenceRow.vue"),
    },

    props: {
        financialYears: {
            type: Array,
            default: () => [],
        },
        voucherRows: {
            type: Array,
            default: () => [],
        },
        companyType: {
            type: Number,
            default: 0,
        },
        accountPlanItems: {
            type: Array,
            default: () => [],
        },
        warningElements: {
            type: Array,
            default: () => [],
        },
        writeComments: {
            type: Boolean,
            default: true,
        },
        periodize: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        debitSummary() {
            let summary = this.voucherRows.reduce((total, amount) => total + amount.debit, 0);
            return summary;
        },
        creditSummary() {
            let summary = this.voucherRows.reduce((total, amount) => total + amount.credit, 0);
            return summary;
        },
        mergeColumns() {
            return this.writeComments ? 1 : 2;
        },
    },

    methods: {
        removeVoucherRow(index) {
            this.$emit("removeVoucherRow", index);
        },

        updateValues(index, { account, debit, credit, comment, startDate, endDate, categoryId, autoGenerated, readOnly }) {
            this.$emit("updateValues", {
                index,
                account,
                debit,
                credit,
                comment,
                startDate,
                endDate,
                categoryId,
                autoGenerated,
                readOnly,
            });
        },

        createNewItemComponent(index) {
            if (this.voucherRows.length > index + 1) {
                return;
            }

            this.$emit("newItemCreation", {
                id: Math.random(),
                account: null,
                debit: 0,
                credit: 0,
                text: "",
                startDate: null,
                endDate: null,
                // isAccural: this.periodize,
                readOnly: false,
                categoryId: null,
            });
        },

        createVoucher() {
            this.$emit("createVoucher");
        },

        showSelectPeriodModal(index, { amount, account }) {
            this.$emit("showSelectPeriodModal", {
                index,
                amount,
                account,
            });
        },
    },
};
</script>

<style scoped>
table td {
    border: 1px solid #ddd;
}
</style>
