<template>
    <table class="w-full">
        <tr class="bg-gray-50">
            <td class="py-1 px-2 text-sm font-semibold">
                <p>
                    KONTO
                </p>
            </td>
            <td class="py-1 px-2 text-sm font-semibold">
                <p>
                    KOMMENTAR
                </p>
            </td>
            <td />
            <td class="py-1 px-2 text-sm font-semibold">
                <p class="text-right">
                    DEBET
                </p>
            </td>
            <td class="py-1 px-2 text-sm font-semibold">
                <p class="text-right">
                    KREDIT
                </p>
            </td>
            <!-- <td class="py-1 px-2 text-sm font-semibold">
                <p class="text-right">
                    SALDO
                </p>
            </td> -->
            <td />
        </tr>
        <NewItemRowNew
            v-for="(item, index) in itemsData"
            :key="item.id"
            :projects="projects"
            :accounts-data="accountsData"
            @approve="createNewItemComponent(index, $event)"
            @removeEntry="removeItem(index)"
            @updateValues="updateValues(index, $event)"
            :pre-loaded-item="item"
            :debit-summary="debitSummary"
            :credit-summary="creditSummary"
            :warning-border="warningElements.includes(item.id)"
            @createVoucher="createVoucher(index)"
            @showSelectPeriodModal="showSelectPeriodModal(index, $event)"
            @saveProjectLinks="saveProjectLinks"
        />
        <SummaryRow :debit-summary="debitSummary" :credit-summary="creditSummary" />
        <DifferenceRow :debit-summary="debitSummary" :credit-summary="creditSummary" />
    </table>
</template>
<script>
export default {
    components: {
        NewItemRowNew: () => import(/* webpackChunkName: "LopandeNewItemRowNew" */ "./NewItemRowNew.vue"),
        SummaryRow: () => import(/* webpackChunkName: "LopandeSummaryRow" */ "./SummaryRow.vue"),
        DifferenceRow: () => import(/* webpackChunkName: "LopandeDifferenceRow" */ "./DifferenceRow.vue"),
    },

    props: {
        projects: {
            type: Array,
            default: () => [],
        },
        itemsData: {
            type: Array,
            default: () => [],
        },
        accountsData: {
            type: Array,
            default: () => [],
        },
        warningElements: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            links: [],
        };
    },

    computed: {
        debitSummary() {
            let summary = this.itemsData.reduce((total, amount) => total + amount.debit, 0);
            return summary;
        },

        creditSummary() {
            let summary = this.itemsData.reduce((total, amount) => total + amount.credit, 0);
            return summary;
        },
    },

    methods: {
        removeItem(index) {
            this.$emit("removeItem", index);
        },

        updateValues(index, { account, debit, credit, comment }) {
            this.$emit("updateValues", {
                index,
                account,
                debit,
                credit,
                comment,
            });
        },

        createNewItemComponent(index) {
            if (this.itemsData.length > index + 1) {
                return;
            }

            this.$emit("newItemCreation", {
                id: Math.random(),
                account: null,
                debit: 0,
                credit: 0,
                text: "",
            });
        },

        saveProjectLinks(payload) {
            console.log("emit dim 2");
            this.$emit("saveProjectLinks", payload);
        },

        createVoucher() {
            this.$emit("createVoucher");
        },

        showSelectPeriodModal(index, { amount, account }) {
            this.$emit("showSelectPeriodModal", {
                index,
                amount,
                account,
            });
        },
    },
};
</script>
<style scoped>
table td {
    border: 1px solid #ddd;
}
</style>
