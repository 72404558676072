<template>
    <div class="voucher-file-handler-container">
        <div class="grid grid-cols-2 gap-2 mb-4">
            <el-upload class="w-full" ref="upload" action="#" :show-file-list="false" :auto-upload="false" multiple :on-change="fileUploadChange" drag>
                <div class="h-full flex flex-col justify-center items-center">
                    <i class="fad fa-cloud-arrow-up text-3xl" />
                    <p class="text-xs">Drop file here or click to upload</p>
                </div>
            </el-upload>
            <div>
                <p v-for="document in filesData" :key="document.documentId" @click="changeFilePreview(document)" class="relative p-1 mb-2 text-xs rounded-lg cursor-pointer hover:bg-blue-100">
                    <i v-if="document.fileType === 1" class="fa-thin fa-file-pdf text-2xl mr-1" />
                    <i v-if="document.fileType === 4 || document.fileType === 5" class="fa-thin fa-file-image text-2xl mr-1" />
                    {{ document.fileName }}
                    <span @click="deleteFile(document.documentId)" class="absolute flex justify-center items-center top-1 right-1 w-4 h-4 bg-red-400 text-white text-xs rounded-md">
                        <i class="fa-solid fa-x" />
                    </span>
                </p>
            </div>
        </div>
        <img v-if="selectedDocument.fileType === 4 || selectedDocument.fileType === 5" :src="selectedDocument.fileContent" alt="" />
        <object v-if="selectedDocument.fileType === 1" :data="url(selectedDocument.fileContent)" type="application/pdf" width="100%" height="600" />
    </div>
</template>
<script>
export default {
    props: {
        filesData: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            selectedDocument: {
                fileType: 0,
            },
        };
    },

    watch: {
        filesData() {
            this.selectedDocument = {
                fileType: 0,
            };
        },
    },

    methods: {
        url(file) {
            // Decode base64 to string
            const decoded = Buffer.from(file, "base64").toString("binary");
            // Convert string into byte array
            const view = new Uint8Array(decoded.length);
            for (var i = 0; i < decoded.length; i++) {
                view[i] = decoded.charCodeAt(i);
            }
            const blob = new Blob([view], { type: "application/pdf" });
            return window.URL.createObjectURL(blob);
        },

        changeFilePreview(document) {
            this.selectedDocument = document;
        },

        fileUploadChange(file) {
            this.$emit("uploadFile", file);
        },

        deleteFile(id) {
            this.$emit("delete", {
                documentId: id,
                filesCount: this.filesData.length - 1,
            });
        },
    },
};
</script>
<style>
.voucher-file-handler-container .el-upload-dragger,
.voucher-file-handler-container .el-upload {
    width: 100%;
}
.el-upload-dragger {
    height: 80px;
}
</style>
